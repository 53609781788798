<template>
  <div>
    <h1 STYLE="PADDING:20PX">EL PERFIL PÚBLICO SE MOSTRARÁ ACÁ</h1>
  </div>
</template>
<script>
export default {
  name: 'Profile',
  data() {
    return {};
  },
  mounted: function () {
    this.setLoading(false);
  },
  methods: {},
};
</script>
